/* global DISPLAY_DATE_FORMAT */

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Rodal from 'rodal'
import { Link } from 'react-router'

import 'rodal/lib/rodal.css'
import './styles.css'

function ManageSubscription({
  isMobile,
  cancel,
  subscription,
  downgrade,
  usedFreeTrial
}) {
  const [isDialogVisible, setDialogVisible] = useState(false)
  const [downgrading, setDowngrading] = useState(false) // Track the selected action
  const plan = subscription?.plan
  const onOpenDialog = () => setDialogVisible(true)
  const onCloseDialog = () => {
    setDialogVisible(false)
    setDowngrading(false)
  }

  const handleDowngrading = () => {
    setDowngrading(true)
  }

  return (
    <div className="subscription-section confirmation" id="ManageSubscription">
      <button
        type="button"
        className="manage-subscription-btn"
        onClick={onOpenDialog}
      >
        Manage Subscription
      </button>

      <Rodal
        visible={isDialogVisible}
        onClose={onCloseDialog}
        width={isMobile ? 375 : 600}
        height={350}
        animation="fade"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <div className="header">
            <h2 className="title">Manage Your Subscription</h2>
          </div>
          {!downgrading && (
            <p className="dialog-message">
              Select one of the following options to manage your subscription:
            </p>
          )}
          {downgrading && (
            <p className="dialog-message">
              Are you sure you want to switch your subscription from the Yearly
              Plan to the Monthly Plan? This change will take effect at the
              start of your next billing cycle.
            </p>
          )}
          {!downgrading && (
            <div className="action-buttons">
              <div className="happy-actions">
                {plan === 'Monthly' && (
                  <Link to="/change_subscription" className="btn -mini -orange">
                    Upgrade Subscription
                  </Link>
                )}
                {plan === 'Yearly' && (
                  <button
                    onClick={() => handleDowngrading()}
                    className="btn -mini -blue-2"
                    type="button"
                  >
                    Downgrade Subscription
                  </button>
                )}
              </div>
              <div className="sad-actions">
                <button
                  onClick={() => {
                    onCloseDialog()
                    cancel()
                  }}
                  type="button"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          )}
          {downgrading && (
            <button
              onClick={() => {
                downgrade()
                onCloseDialog()
              }}
              className="btn -mini -blue-2"
              type="button"
              style={{ width: 250 }}
            >
              Downgrade to Monthly
            </button>
          )}
        </div>
      </Rodal>
    </div>
  )
}

ManageSubscription.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default ManageSubscription
