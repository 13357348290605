/* global DISPLAY_DATE_FORMAT */

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router'

import { authInfoReselector as stateSelector } from 'reduxModules/auth/selectors'
import { accountDispatch as dispatcher } from 'reduxModules/auth/dispatchers'

import Orders from 'components/Account/Orders'
import PaymentMethods from 'components/Account/PaymentMethods'
import Addresses from 'components/Account/Addresses/_lazy'
import Profile from '../Profile/index.jsx'
import DeleteAccount from './DeleteAccount'

import 'rodal/lib/rodal.css'

import './styles.css'
import ManageSubscription from './ManageSubscription/index.jsx'

function MyAccount(props) {
  const confirmPayment = () => {
    props.dispatchCancelFreeTrial(true, props.usedFreeTrial)
  }
  const canMakeChanges =
    !props.subscription?.pendingChange && !props.subscription?.pendingDowngrade
  return (
    <>
      <div className="account-section" id="MyAccount">
        <div className="personal-information">
          <div className="subheading">
            <h3 className="account-section__title heading">My Account</h3>
            {!props.profileIsOpen && (
              <button
                className="btn -mini -blue-2"
                type="button"
                onClick={() => props.openProfileSection()}
                style={{ height: 20 }}
              >
                Edit
              </button>
            )}
          </div>
          {!props.profileIsOpen ? (
            <div className="grid">
              <div className="first-name">
                <span>first name</span>
                <span>{props.firstName}</span>
              </div>
              <div className="last-name">
                <span>last name</span>
                <span>{props.lastName}</span>
              </div>
              <div className="email">
                <span>email</span>
                <span>{props.email}</span>
              </div>
            </div>
          ) : (
            <Profile cancel={props.closeProfileSection} />
          )}
          <Link
            to="/account/change_password"
            className="change-password"
            style={{ display: !props.createdByProxy ? 'block' : 'none' }}
          >
            Change Password
          </Link>
          {props.subscription && !props.createdByProxy && (
            <>
              <h3 className="account-section__title">Subscription</h3>
              <div className="subscription-info">
                <div className="left-section">
                  <div className="description">
                    <i className={`icon-monster-${props.subscription.rank}`} />
                    <div className="plan-information">
                      <span className="plan-name">
                        {props.subscription.plan}
                      </span>
                      {props.subscription.nextBillingDate &&
                        !props.subscription.free &&
                        !props.subscription?.pendingChange && (
                          <span className="billing-date">
                            Billing Date:{' '}
                            {moment(props.subscription.nextBillingDate).format(
                              DISPLAY_DATE_FORMAT
                            )}
                          </span>
                        )}
                      {canMakeChanges && (
                        <ManageSubscription
                          cancel={() =>
                            props.dispatchCancelSubscription(props.email)
                          }
                          subscription={props.subscription}
                          downgrade={props.downgradeSubscription}
                          isMobile={props.isMobile}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-section">
                  {props?.subscription.rank < 3 && !props.subscription.free && (
                    <Link to="/change_subscription">Upgrade</Link>
                  )}
                  {props.subscription?.pendingChange &&
                    !props.subscription?.pendingDowngrade && (
                      <div className="pending-message">
                        <span className="message">
                          Your subscription cancellation is pending and will
                          take effect on
                        </span>
                        <span
                          className="date"
                          style={{
                            display: props.subscription.nextBillingDate
                              ? 'inline-block'
                              : 'none'
                          }}
                        >
                          {' '}
                          {moment(props.subscription.nextBillingDate).format(
                            DISPLAY_DATE_FORMAT
                          )}
                        </span>
                      </div>
                    )}
                  {props.subscription?.pendingDowngrade &&
                    props.subscription?.pendingChange && (
                      <div className="pending-message">
                        <span className="message">
                          Your subscription downgrade is pending and will take
                          effect on
                        </span>
                        <span
                          className="date"
                          style={{
                            display: props.subscription.nextBillingDate
                              ? 'inline-block'
                              : 'none'
                          }}
                        >
                          {' '}
                          {moment(props.subscription.nextBillingDate).format(
                            DISPLAY_DATE_FORMAT
                          )}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
          {props.couldGiftSubscription ||
            (props.canGiftSubscription && (
              <div className="subscription-gift-notice">
                {props.couldGiftSubscription && (
                  <p className="subscription-gift-notice__text">
                    Pay for your current subscription and give a free matching
                    subscription to a family member or friend.
                  </p>
                )}
                {props.canGiftSubscription && (
                  <p className="subscription-gift-notice__text">
                    Congratulations! You are eligible to give a free matching
                    subscription to a family member or friend.
                  </p>
                )}
                <button
                  className="subscription-gift-notice__cta btn -wide -orange"
                  onClick={confirmPayment}
                  type="button"
                >
                  Gift Matching Subscription
                </button>
              </div>
            ))}
        </div>
      </div>
      <div className="account-section">
        <PaymentMethods />
      </div>
      <div className="account-section">
        <Addresses />
      </div>
      <div className="account-section">
        <Orders />
      </div>
      <div className="account-section">
        <DeleteAccount isMobile={props.isMobile} />
      </div>
    </>
  )
}

MyAccount.propTypes = {
  couldGiftSubscription: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  subscription: PropTypes.shape({
    plan: PropTypes.string.isRequired,
    free: PropTypes.bool.isRequired,
    rank: PropTypes.number.isRequired
  }),
  dispatchCancelSubscription: PropTypes.func.isRequired
}

MyAccount.defaultProps = {
  firstName: '',
  lastName: '',
  subscription: null
}

export default connect(stateSelector, dispatcher)(MyAccount)
