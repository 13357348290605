import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import Helmet from 'react-helmet';
import { tutorialsStatus } from '../../../redux/modules/tutorials/index';
import CourseTabs from '../../Courses/CourseTabs';
import AssetsGallery from '../../generic/AssetsGallery/_lazy';
import SectionTitle from '../../generic/SectionTitle';
import ChevronLeftIconSmall from '../../icons/ChevronLeftIconSmall';
import CourseArtist from '../../Courses/CourseArtist';
import CourseComments from '../../Courses/CourseComments/_lazy';
import CoursePurchase from '../../Courses/CoursePurchase/index.jsx';
import CourseRelated from '../../Courses/CourseRelated';
import Banner from '../../generic/FeaturedElement/index.jsx';
import VideoModal from '../../generic/VideoModal';
import TutorialPlayer from '../TutorialPlayer/index';
import LeadMagnetFunnelForm from './LeadMagnetFunnelForm';
import SubscribeDialogCTA from '../../App/SubscribeDialogCTA';
import { JSONLD } from 'react-structured-data';
import { Generic } from 'react-structured-data';
import { Field } from 'redux-form';
import RenderField from '../../generic/RenderedField';
import { validators } from '../../../redux/modules/utils/forms';
import Overview from './Overview/_lazy';
import Curriculum from './Curriculum/index';
import Materials from './Materials/index.jsx';
export default function () {
    function repeatFbImage1(props, subscribed, metaTitle, metaDescription, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function scopeMetaTitleMetaDescription2(props, subscribed) {
        var metaTitle = props.metaTitle || props.title;
        var metaDescription = props.metaDescription || props.description;
        return React.createElement.apply(this, [
            Helmet,
            {},
            React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'),
            React.createElement('meta', {
                'name': 'description',
                'content': metaDescription
            }),
            React.createElement('meta', {
                'property': 'og:title',
                'content': metaTitle
            }),
            React.createElement('meta', {
                'property': 'og:description',
                'content': metaDescription
            }),
            !props.facebookShareImages.length && _.has(props, 'photo.original') ? React.createElement('meta', {
                'property': 'og:image',
                'content': props.photo.original,
                'key': '2299'
            }) : null,
            props.facebookShareImages.length ? _.map(props.facebookShareImages, repeatFbImage1.bind(this, props, subscribed, metaTitle, metaDescription)) : null,
            React.createElement('meta', {
                'property': 'og:type',
                'content': 'article'
            })
        ]);
    }
    function scopeCleanCategories3(props, subscribed) {
        var cleanCategories = _.uniqBy(this.state.purchaseCategoriesSidebar, 'name');
        return React.createElement('section', { 'className': 'wrapper additional-purchase-options' }, React.createElement(SectionTitle, {}, 'Additional Purchasing Options'), this.state.purchaseCategoriesSidebar ? React.createElement(CoursePurchase, {
            'categories': cleanCategories,
            'selectPlan': props.selectPlan,
            'isMobile': props.isMobile,
            'isSubscribed': props.isSubscribed,
            'isOwned': props.owned,
            'onSide': true,
            'isTutorial': true,
            'isSimpleSection': true,
            'key': '4934'
        }) : null);
    }
    function scopePropsSubscribed4() {
        var props = this.props;
        var subscribed = !_.isNull(props.subscription);
        return React.createElement('article', { 'id': 'TutorialDetail' }, scopeMetaTitleMetaDescription2.apply(this, [
            props,
            subscribed
        ]), props.photo ? React.createElement('div', {
            'className': 'header',
            'key': '2670'
        }, !this.state.watchMode ? React.createElement('div', {
            'className': 'wrapper tutorial-detail-wrapper',
            'key': '2715'
        }, React.createElement(Banner, {
            'title': props.title,
            'linkTo': this.getLinkTo(),
            'btn': {
                text: this.getBannerText(),
                action: this.getLinkTo()
            },
            'description': props.description,
            'photo': props.photo,
            'onClick': props.videoPreviewInfo && props.tutorialTrailerStatus === tutorialsStatus.tutorialTrailerSuccess ? () => props.toggleVideoPreview() : null,
            'isMobile': props.isMobile,
            'usedFreeTrial': props.usedFreeTrial,
            'artist': `${ props.artist.firstName } ${ props.artist.lastName }`,
            'new': props.new
        })) : null, this.state.watchMode && this.props.id ? React.createElement('div', { 'key': '3377' }, React.createElement(TutorialPlayer, {
            'location': props.location,
            'artist': props.artist,
            'isAuth': props.isAuth,
            'slug': props.slug,
            'router': props.router
        })) : null) : null, React.createElement('section', { 'className': 'wrapper overview' }, React.createElement(Overview, _.assign({}, { 'categories': this.state.purchaseCategoriesSidebar }, props))), this.props.chaptered ? React.createElement('section', {
            'className': 'wrapper curriculum',
            'key': '3786'
        }, React.createElement(SectionTitle, {}, 'Curriculum'), React.createElement(Curriculum, props)) : null, React.createElement('section', { 'className': 'wrapper materials' }, React.createElement(SectionTitle, {}, 'Materials'), React.createElement(Materials, props)), React.createElement('div', { 'className': 'gallery-holder' }, props.assets && props.assets.length ? React.createElement(AssetsGallery, {
            'assets': props.assets,
            'isMobile': props.isMobile,
            'showThumbnails': true,
            'key': '4112'
        }) : null), !_.isEmpty(props.artist) ? [React.createElement(CourseArtist, _.assign({}, { 'key': '42491' }, props.artist))] : null, !props.isSubscribed ? React.createElement('section', {
            'className': 'subscribe-cta-banner',
            'key': '4357'
        }, !props.usedFreeTrial ? React.createElement('p', {
            'className': 'subscribe-cta-banner__content',
            'key': '4439'
        }, '\n      Free 7-day Trial\n    ') : null, React.createElement(SubscribeDialogCTA, {}, React.createElement('div', { 'className': 'subscribe-cta-banner__cta btn -wide -orange' }, React.createElement('span', {}, 'Subscribe to Watch')))) : null, scopeCleanCategories3.apply(this, [
            props,
            subscribed
        ]), props.relatedTutorials.length ? React.createElement('div', {
            'className': 'wrapper',
            'key': '5258'
        }, props.relatedTutorials && props.tags ? React.createElement(CourseRelated, {
            'courses': props.relatedTutorials,
            'tags': props.tags,
            'isSubscribed': props.isSubscribed,
            'isMobile': props.isMobile,
            'key': '5322'
        }) : null) : null, !props.isAuth ? React.createElement('div', { 'key': '5543' }, React.createElement(LeadMagnetFunnelForm, { 'tag': 'Course Email Lead' })) : null, React.createElement(CourseComments, { 'pathName': props.location.pathname }), props.videoPreviewInfo ? React.createElement(VideoModal, {
            'url': props.videoPreviewInfo.path,
            'visible': props.videoPreview,
            'close': () => props.toggleVideoPreview(),
            'isYoutube': props.videoPreviewInfo.isYoutube,
            'subtitles': props.videoPreviewInfo.subtitles,
            'key': '5693'
        }) : null, props.metaTitle && props.metaDescription ? React.createElement(JSONLD, { 'key': '5976' }, React.createElement(Generic, {
            'type': 'course',
            'jsonldtype': 'Course',
            'schema': {
                name: `${ props.metaTitle }`,
                description: `${ props.metaDescription }`
            }
        }, React.createElement(Generic, {
            'type': 'provider',
            'jsonldtype': 'Organization',
            'schema': {
                name: 'Stan Winston School of Characters Arts',
                sameAs: 'https://www.stanwinstonschool.com'
            }
        }))) : null);
    }
    return scopePropsSubscribed4.apply(this, []);
}